import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import secureLocalStorage from './utils/secureLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useDispatch, batch } from 'react-redux';
import { folderActions, suiteActions, testPlanActions } from './store/actions';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
export const AppTopbar = props => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const menu = useRef(null);
  const onLogout = () => {};
  let items = [
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        secureLocalStorage.clear();
        batch(() => {
          dispatch({ type: 'RESET' });
          dispatch({ type: folderActions.RESET });
          dispatch({ type: suiteActions.RESET_SUITE });
          dispatch({ type: testPlanActions.RESET });
        });

        setTimeout(() => {
          navigate('/login');
        }, 100);
      },
    },
  ];
  return (
    <div className='layout-topbar'>
      <Tooltip target={'.tooltip-element'} position={'bottom'}  />
      <button type='button' className='p-link  layout-menu-button layout-topbar-button' onClick={props.onToggleMenuClick}>
        <i className='pi pi-bars' />
      </button>
      <Link to='/' className='layout-topbar-logo'>
        <img src={props.layoutColorMode === 'light' ? '/qafasttrack.svg' : '/qafasttrackwhite.svg'} alt='logo' />
      </Link>

      <button type='button' className='p-link layout-topbar-menu-button layout-topbar-button' onClick={props.onMobileTopbarMenuClick}>
        <i className='pi pi-ellipsis-v' />
      </button>

      <ul className={classNames('layout-topbar-menu lg:flex origin-top', { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
        {/* <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li> */}
        <li>
          <Button  disabled="true" link icon={ <i className='pi pi-download'></i>}  className='p-link layout-topbar-button ' tooltip='Utility: Download link coming soon!' tooltipOptions={{ showOnDisabled: true , position: "bottom" , at: "center bottom-3" , autoHide :"false"   }}>
           
          </Button>
        </li>
        <li>
          <Link to='https://qafasttrack.atlassian.net/wiki/spaces/QAFastTrac/overview' target='_blank' className='p-link layout-topbar-button tooltip-element' data-pr-tooltip='Documentation Link'>
            <i className='pi pi-book'></i>
          </Link>
        </li>
        <li>
          <button onClick={event => menu.current.toggle(event)} className='p-link layout-topbar-button'>
            <i className='pi pi-user' />
            <span>Profile</span>
            <Menu model={items} popup ref={menu} />
          </button>
        </li>
      </ul>
    </div>
  );
};
